import { createContext, useEffect, useReducer } from "react"
import { createSlice } from "@reduxjs/toolkit"
import axiosInstance from "@/axiosInstance"

const initialState = {
  isInitialized: false,
  pricingCategories: null,
  errors: [],
}

const slice = createSlice({
  name: "pricing",
  initialState,
  reducers: {
    initialize(state, action) {
      const { isInitialized, pricingCategories } = action.payload
      state.isInitialized = isInitialized
      state.pricingCategories = pricingCategories
    },
  },
})

export const { reducer } = slice

export const initializePricing = () => async (dispatch) => {
  axiosInstance
    .get(`pricings/categories/`)
    .then((res) => {
      console.log(res.data)
      dispatch(slice.actions.initialize({ isInitialized: true, pricingCategories: res.data }))
    })
    .catch((error) => {
      console.log(error)
      dispatch(slice.actions.initialize({ isInitialized: false, pricingCategories: null }))
    })
}

export default slice
