import React from "react"
import clsx from "clsx"
import PropTypes from "prop-types"
import { makeStyles } from "@mui/styles"
import { Drawer } from "@mui/material"

import { SidebarNav } from "./components"

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: "100%",
    maxWidth: 325,
  },
  root: {
    height: "100%",
    padding: theme.spacing(1),
  },
  nav: {
    marginBottom: theme.spacing(1),
  },
}))

const Sidebar = (props) => {
  const { pages, open, variant, onClose, className, ...rest } = props

  const classes = useStyles()

  return (
    <Drawer anchor="left" classes={{ paper: classes.drawer }} onClose={() => onClose()} open={open} variant={variant}>
      <div {...rest} className={clsx(classes.root, className)}>
        <SidebarNav className={classes.nav} pages={pages} onClose={onClose} />
      </div>
    </Drawer>
  )
}

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
}

export default Sidebar
