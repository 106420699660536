import axiosInstance from "@/axiosInstance"
import { createSlice } from "@reduxjs/toolkit"
import { showArtist } from "./artists"

const initialState = {
  comment: {},
  comments: [],
  errors: [],
}

const slice = createSlice({
  name: "comments",
  initialState,
  reducers: {
    listComments(state, action) {
      state.comments = action.payload
    },
    showComment(state, action) {
      console.log("action.payload", action.payload)
      state.comment = action.payload
    },
    showComment_error(state, action) {
      state.errors.push(action.payload)
      console.log(action.payload)
      // window.location.replace("/not-found") // Redirect if the resource is not found
    },
    createComment(state, action) {
      state.comments.push(action.payload)
      state.errors = []
    },
    createComment_error(state, action) {
      state.errors = action.payload
    },
    editComment(state, action) {
      state.comment = action.payload
    },
    updateComment(state, action) {
      // listComments()
      state.errors = []
    },
    updateComment_error(state, action) {
      state.errors = action.payload
    },
    deleteComment(state, action) {
      state.comment = state.comments.filter((Comment, index) => Comment.id !== action.payload.id)
      state.errors = []
    },
  },
})

export const { reducer } = slice

// export const listComments = (product_id) => async (dispatch) => {
//   axiosInstance.get(`/product/${product_id}/comments/`).then((res) => {
//     dispatch(slice.actions.listComments(res.data))
//   })
// }
// export const showComment = (pk) => async (dispatch) => {
//   console.log("showComment")
//   axiosInstance
//     .get(`/comments/${pk}`)
//     .then((res) => {
//       // console.log("pk:", pk)
//       // console.log(res)
//       dispatch(slice.actions.showComment(res.data))
//     })
//     .catch((err) => {
//       dispatch(slice.actions.showComment_error(err.response.data))
//     })
// }

export const createComment = (formState, setLoading, setFormState, initialFormState, setComments) => async (dispatch) => {
  // console.log("createComment")
  // console.log(formState)

  axiosInstance
    .post(`/comments/`, formState.values)
    .then((res) => {
      console.log(res.data)
      // dispatch(showArtist(formState.values.artist_id, setLoading)) // Do this to refresh the list of comments
      // TODO I think there's a better way of doing the above - bc it won't work in other places, e.g. the feed.
      // Could we return the product's comments?
      dispatch(slice.actions.createComment(res.data))
      setFormState(initialFormState)
      // Instead of refreshing the whole feed, we setComments
      setComments((comments) => [...comments, res.data])
      setLoading(false)
    })
    .catch((err) => {
      console.log(err.response.data)
      dispatch(slice.actions.createComment_error(err.response.data))
    })
}

// export const editComment = (comments) => async (dispatch) => {
//   dispatch(slice.actions.editComment(comments))
// }

export const updateComment = (formState, comment, setComments, handle, setEdit, setLoading) => async (dispatch) => {
  console.log(formState)
  axiosInstance
    .patch(`/comments/${comment.id}/`, formState.values)
    .then((res) => {
      // console.log("success1")
      comment = res.data
      // console.log("success2")
      // console.log("success3")
      // console.log(comment)
      dispatch(slice.actions.updateComment(res.data))

      dispatch(showArtist(handle, setEdit, setLoading)) // Do this to refresh the list of comments
      // console.log("success4")
    })
    .catch((error) => {
      console.log("error")
      console.log(error.response)
      dispatch(slice.actions.updateComment_error(error.response))
    })
}

export const deleteComment = (comment, refreshHandle, setOpenDeleteComment) => async (dispatch) => {
  axiosInstance
    .delete(`/comments/${comment.id}/`, comment)
    .then((res) => {
      dispatch(showArtist(refreshHandle, setOpenDeleteComment)) // Do this to refresh the list of comments
      // dispatch(slice.actions.deleteComment(comment))
    })
    .catch((err) => {
      dispatch(slice.actions.deleteCommentFail(err.response.data))
    })
}

export default slice
