import axiosInstance from "@/axiosInstance"
import { createSlice } from "@reduxjs/toolkit"
import { toast } from "react-toastify"

const initialState = {
  nfts: [],
  nft: {}, // Should be an object rather than an array
  nftsIsLoaded: false,
  errors: [],
}

const slice = createSlice({
  name: "nfts",
  initialState,
  reducers: {
    listNfts(state, action) {
      state.nfts = action.payload
      state.nftsIsLoaded = true
    },
    showNft(state, action) {
      state.nft = action.payload
    },
    showNft_error(state, action) {
      state.errors.push(action.payload)
      console.log(action.payload)
      // window.location.replace("/not-found") // Redirect if the resource is not found
    },
    createNft(state, action) {
      state.nft = action.payload
      state.errors = []
    },
    createNft_error(state, action) {
      state.errors = action.payload
    },
    editNft(state, action) {
      state.nft = action.payload
    },
    updateNft(state, action) {
      // listNfts()
      state.errors = []
    },
    updateNft_error(state, action) {
      state.errors = action.payload
    },
    getLikedBy(state, action) {
      state.likedByList = action.payload
      state.errors = []
    },
  },
})

export const { reducer } = slice

export const listNfts = () => async (dispatch) => {
  axiosInstance.get(`/orders/nfts/`).then((res) => {
    console.log("listNfts")
    console.log(res.data)
    dispatch(slice.actions.listNfts(res.data))
  })
}
export const searchNfts = (query) => async (dispatch) => {
  axiosInstance.get(`/orders/nfts/search?query=${query}`).then((res) => {
    dispatch(slice.actions.listNfts(res.data))
  })
}
export const showNft = (pk) => async (dispatch) => {
  axiosInstance
    .get(`/orders/nfts/${pk}`)
    .then((res) => {
      dispatch(slice.actions.showNft(res.data))
    })
    .catch((err) => {
      dispatch(slice.actions.showNft_error(err.response?.data))
    })
}

export const createNft = (formData, setOpenPopup, setLoading) => async (dispatch) => {
  axiosInstance
    .post(`/orders/nfts/`, formData)
    .then((res) => {
      dispatch(slice.actions.createNft(res.data))
      setOpenPopup(true)
      setLoading(false)
    })
    .catch((err) => {
      console.log(err.response.data)
      if (err.response.data.file) {
        toast.info(err.response.data.file[0], {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      }
      dispatch(slice.actions.createNft_error(err.response.data))
      setLoading(false)
    })
}

export const editNft = (nfts) => async (dispatch) => {
  dispatch(slice.actions.editNft(nfts))
}

export const updateNft = (formState, setOpenEditBrief, setOpenEditArtworkInformation, setOpenEditPrice, setLoading) => async (dispatch) => {
  console.log("formState")
  console.log(formState)
  axiosInstance
    .put(`/orders/nfts/${formState.values.id}/`, formState.values)
    .then((res) => {
      setOpenEditBrief && setOpenEditBrief(false)
      setOpenEditArtworkInformation && setOpenEditArtworkInformation(false)
      setOpenEditPrice && setOpenEditPrice(false)
      setLoading && setLoading(false)
      dispatch(slice.actions.updateNft(res.data))
      dispatch(showNft(formState.values.id))
    })
    .catch((err) => {
      console.log(err)
      console.log(err.response.data)
      dispatch(slice.actions.updateNft_error(err.response.data))
      if (err.response.data.detail) {
        toast.info(err.response.data.detail, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      }
      setLoading && setLoading(false)
    })
}

export default slice
