import axiosInstance from "@/axiosInstance"
import { createSlice } from "@reduxjs/toolkit"
import { toast } from "react-toastify"

const initialState = {
  isAuthenticated: false,
  isRegistered: false,
  isInitialized: false,
  user: null,
  errors: [],
}

const slice = createSlice({
  name: "users",
  initialState,
  reducers: {
    initialize(state, action) {
      const { isAuthenticated, isLoggingIn, user } = action.payload
      state.isAuthenticated = false
      state.isInitialized = true
      state.user = user
      state.search = ""
    },
    login(state, action) {
      const { user, access_token, refresh_token, email_verification } = action.payload
      localStorage.setItem("access_token", access_token)
      localStorage.setItem("refresh_token", refresh_token)
      if (email_verification) {
        localStorage.setItem("email_verification", email_verification)
      }
      state.isAuthenticated = true
      state.user = user
      window.location.replace("/")
    },
    login_error(state, action) {
      state.isAuthenticated = false
      state.errors = action.payload
    },
    logout(state) {
      localStorage.removeItem("access_token")
      localStorage.removeItem("refresh_token")
      axiosInstance.defaults.headers["Authorization"] = null
      state.isAuthenticated = false
      state.isInitialized = false
      state.isLoggingOut = true
      state.user = null
    },
    createUser: (state, action) => {
      console.log("register_success")
      const { user } = action.payload
      state.user = user
      state.isRegistered = true
      // window.location.href = "/signin/"
    },
    createUser_error(state, action) {
      state.isAuthenticated = false
      state.errors = action.payload // setting the error
    },
    updateUser(state, action) {
      state.user = action.payload
    },
    updateUser_error(state, action) {
      console.log("updateUser_error")
      state.error = action.payload
    },
    updateUserImage(state, action) {
      state.user = action.payload
    },
    updateUserImage_error(state, action) {
      state.error = action.payload
    },
    updateNotifications(state, action) {
      state.notifications = action.payload
    },
    listReferrals(state, action) {
      state.referrals = action.payload
      state.referralsIsLoaded = true
    },
    listReferrals_error(state, action) {
      state.errors.push(action.payload)
      console.log(action.payload)
    },
  },
})

export const { reducer } = slice

export const login = (formState, setLoading) => async (dispatch) => {
  console.log("Login!")

  axiosInstance
    .post(`/users/jwt/obtain/`, {
      email: formState.values.email,
      password: formState.values.password,
    })
    .then((res) => {
      // console.log(res)
      // console.log(res.data)
      axiosInstance.defaults.headers["Authorization"] = "JWT " + res.data.access
      axiosInstance.get(`/users/user/`).then((userResponse) => {
        let user = userResponse.data
        dispatch(
          slice.actions.login({
            user,
            access_token: res.data.access,
            refresh_token: res.data.refresh,
          })
        )
        setLoading && setLoading(false)
      })
    })
    .catch((error) => {
      console.log("LOGIN_ERROR")
      console.log(error)
      console.log(error.response)
      console.log(error.response.data)
      if (typeof error.response !== "undefined" && setLoading) {
        setLoading(false) // It's more helpful if it keeps spinning when you can't connect because then you know it's not connecting
      }
      dispatch(slice.actions.login_error(error.response.data))
    })
}

export const verifyEmail = (token, setAwaitingResponse) => async (dispatch) => {
  // const access_token = await authApi.register({ email, name, password });
  // const user = await authApi.me(access_token);
  // localStorage.setItem('access_token', access_token);
  console.log("verifyEmail slice")

  const response = await axiosInstance
    .get(`/users/email-verification/?token=${token}`)
    .then(async (res) => {
      // setAwaitingResponse(false)
      console.log(res.data)
      console.log(res.data.message)
      // Performs a login
      console.log("Logging in...")
      axiosInstance.defaults.headers["Authorization"] = "JWT " + res.data.access
      axiosInstance.get(`/users/user/`).then((userResponse) => {
        let user = userResponse.data

        dispatch(
          slice.actions.login({
            user,
            access_token: res.data.access,
            refresh_token: res.data.refresh,
            email_verification: true,
          })
        )
      })
    })
    .catch((error) => {
      console.log(error.response)
      console.log(error.response.data)
      localStorage.removeItem("access_token")
      localStorage.removeItem("refresh_token")
      setAwaitingResponse(false)
      // dispatch(slice.actions.verifyEmail_error(error.response.data))
    })
}

export const logout = () => (dispatch) => {
  console.log("logout")

  // console.log(localStorage.getItem('refresh_token'))
  const response = axiosInstance.post("/users/jwt/blacklist/", {
    refresh_token: localStorage.getItem("refresh_token"),
  })
  dispatch(slice.actions.logout(response.data))
  window.location.replace("/")
}
export const contactEmail = (data, setLoading, setFormState, handleBottombarClose, initialFieldValues) => (dispatch) => {
  console.log("contactEmail")

  // console.log(localStorage.getItem('refresh_token'))
  const response = axiosInstance
    .post("/utils/emails/contact-us", data)
    .then((res) => {
      setLoading(false)
      setFormState(initialFieldValues)
      handleBottombarClose()
      toast.success("Message submitted! Thank you. We'll respond to your message ASAP.", {
        position: "top-center",
        autoClose: 7500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    })
    .catch((error) => {
      setLoading(false)
      console.log(error.response)
      console.log(error.response.data)
      toast.error(error.response.data.detail, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    })
}
export const create = (formState, setLoading) => async (dispatch) => {
  var user
  // const access_token = await authApi.register({ email, name, password });
  // const user = await authApi.me(access_token);

  // localStorage.setItem('access_token', access_token);
  console.log("create slice")

  const response = await axiosInstance
    .post(`/users/`, formState.values)
    .then((res) => {
      user = res.data
      dispatch(slice.actions.createUser({ user }))
      setLoading && setLoading(false)
    })
    .catch((error) => {
      console.log(error.response)
      console.log(error.response.data)
      localStorage.removeItem("access_token") // There's a chance the user has wrong/old tokens and that causes a 401 error from the server, so clear them out here in case
      localStorage.removeItem("refresh_token")
      if (typeof error.response !== "undefined" && setLoading) {
        setLoading(false) // It's more helpful if it keeps spinning when you can't connect because then you know it's not connecting
      }
      dispatch(slice.actions.createUser_error(error.response.data))
    })
}

export const updateSettings = (user, setting, value) => async (dispatch) => {
  console.log(user, setting, value)
  localStorage.setItem(setting, value)
  dispatch(
    slice.actions.updateUser({
      ...user,
      settings: {
        ...user.settings,
        [setting]: value,
      },
    })
  )
}

export const update = (formData, user, setEditing) => async (dispatch) => {
  console.log("User update")
  console.log(formData)
  // console.log("formValues", formData.values)
  // console.log("user", user)
  axiosInstance
    .patch(`/users/${user.id}/`, formData.values)
    .then((res) => {
      console.log("success1")
      user = res.data
      console.log("success2")
      if (setEditing) {
        setEditing(false)
      }
      console.log("success3")
      dispatch(slice.actions.updateUser(user))
      console.log("success4")
      console.log(user)
    })
    .catch((error) => {
      console.log("error")
      console.log(error.response)
      console.log(error.response.data.detail)
      toast.error(error.response.data.detail, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
      dispatch(slice.actions.updateUser_error(error.response))
    })
}

export const requestPasswordReset = (formData) => async (dispatch) => {
  console.log("requestPasswordReset")
  console.log(formData)

  axiosInstance
    .post(`/users/password-reset-request/`, formData.values)
    .then((res) => {
      // dispatch(slice.actions.updateUser(user))
      toast.success(res.data.success, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        onClose: () => window.location.replace("/"),
      })
    })
    .catch((error) => {
      console.log("error")
      console.log(error.response)
      console.log(error.response.data.detail)
      toast.error(error.response.data.detail, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
      dispatch(slice.actions.updateUser_error(error.response))
    })
}
export const resetPassword = (formData) => async (dispatch) => {
  console.log("resetPassword")
  console.log(formData)

  axiosInstance
    .patch(`/users/password-reset/`, formData.values)
    .then((res) => {
      var user = res.data
      console.log(user)
      window.location.replace("/signin")
      localStorage.setItem("password_reset", true)
      dispatch(slice.actions.updateUser(user))
    })
    .catch((error) => {
      console.log("error")
      console.log(error.response)
      console.log(error.response.data.detail)
      toast.error(error.response.data.detail, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
      dispatch(slice.actions.updateUser_error(error.response))
    })
}

export const initializeUser = () => async (dispatch) => {
  // Initialize Defaults
  let network = "TestNet"
  // if (window.localStorage.getItem("network")) {
  //   network = window.localStorage.getItem("network")
  // }
  if (location.hostname.includes("testnet")) {
    network = "TestNet"
  } else {
    network = "MainNet"
  }
  dispatch(
    slice.actions.initialize({
      user: {
        settings: {
          network: network,
          themeMode: localStorage.getItem("themeMode") ? localStorage.getItem("themeMode") : "dark",
          include_coti: localStorage.getItem("include_coti") === null ? true : localStorage.getItem("include_coti") === "true",
        },
      },
    })
  )
}

export default slice
