import { Image } from "@/components/thefront/atoms"
import Typography from "@mui/material/Typography"
import React from "react"

export default function Logo({ classes, image }) {
  return (
    <Typography
      variant="h6"
      noWrap
      component="div"
      sx={{
        display: { md: "flex" },
      }}
    >
      <div className={classes.logoContainer}>
        <Image className={classes.logoImage} src={image} alt={import.meta.env.VITE_NAME} lazy={false} />
      </div>
    </Typography>
  )
}
