export const pages = {
  landings: {
    title: "Landings",
    id: "landing-pages",
    children: {
      services: {
        groupTitle: "Services",
        pages: [
          {
            title: "Coworking",
            href: "/coworking",
          },
          {
            title: "Rental",
            href: "/rental",
          },
          {
            title: "Job Listing",
            href: "/job-listing",
          },
          {
            title: "E-Learning",
            href: "/e-learning",
          },
          {
            title: "E-commerce",
            href: "/e-commerce",
          },
          {
            title: "Expo",
            href: "/expo",
          },
        ],
      },
      apps: {
        groupTitle: "Apps",
        pages: [
          {
            title: "Desktop App",
            href: "/desktop-app",
          },
          {
            title: "Mobile App",
            href: "/mobile-app",
          },
        ],
      },
      web: {
        groupTitle: "Web",
        pages: [
          {
            title: "Marketing",
            href: "/",
          },
          {
            title: "Overview",
            href: "/home",
          },
          {
            title: "Basic",
            href: "/web-basic",
          },
          {
            title: "Service",
            href: "/service",
          },
          {
            title: "Startup",
            href: "/startup",
          },
          {
            title: "Enterprise",
            href: "/enterprise",
          },
          {
            title: "Cloud Hosting",
            href: "/cloud-hosting",
          },
          {
            title: "Agency",
            href: "/agency",
          },
          {
            title: "Design Company",
            href: "/design-company",
          },
          {
            title: "Logistics",
            href: "/logistics",
          },
        ],
      },
    },
  },
  pages: {
    title: "Pages",
    id: "supported-pages",
    children: {
      career: {
        groupTitle: "Career",
        pages: [
          {
            title: "Lising",
            href: "/career-listing",
          },
          {
            title: "Lising Minimal",
            href: "/career-listing-minimal",
          },
          {
            title: "Opening",
            href: "/career-opening",
          },
        ],
      },
      helpCenter: {
        groupTitle: "Help center",
        pages: [
          {
            title: "Overview",
            href: "/help-center",
          },
          {
            title: "Article",
            href: "/help-center-article",
          },
        ],
      },
      company: {
        groupTitle: "Official",
        pages: [
          {
            title: "COTI",
            href: "https://coti.io",
            external: true,
          },
          {
            title: "Djed",
            href: "https://djed.xyz/",
            external: true,
          },
          {
            title: "Viper",
            href: "https://pay.coti.io/",
            external: true,
          },
          {
            title: "Explorer",
            href: "https://explorer.coti.io/",
            external: true,
          },
          {
            title: "Bridge",
            href: "https://bridge.coti.io/",
            external: true,
          },
          {
            title: "CVI",
            href: "https://cvi.finance/",
            external: true,
          },
          // {
          //   title: "Refer a Friend",
          //   href: "/referrals",
          // },

          // {
          //   title: "Artist's Declaration",
          //   href: "/artists-declaration",
          // },
          // {
          //   title: "Guidance For Buyers",
          //   href: "/guidance-for-buyers",
          // },
        ],
      },
      blog: {
        groupTitle: "Social",
        pages: [
          {
            title: "Twitter",
            href: "https://twitter.com/COTInetwork",
            external: true,
          },
          // {
          //   title: "Refunds & Cancellations", // I should turn this into a page on "Buyer's guide"
          //   href: "/contact-us",
          // },
          {
            title: "Discord",
            href: "https://discord.com/invite/wfAQfbc3Df",
            external: true,
          },
          {
            title: "Telegram",
            href: "https://t.me/COTInetwork",
            external: true,
          },
          // {
          //   title: "What are NFTs?",
          //   href: "https://blog.cotimarketcap.com/what-do-nfts-mean-for-art-fans-buyers-and-collectors/",
          //   external: "true",
          // },
          {
            title: "GitHub",
            href: "https://github.com/coti-io",
            external: true,
          },
          {
            title: "YouTube",
            href: "https://www.youtube.com/@COTIGroup",
            external: true,
          },
        ],
      },
      contact: {
        groupTitle: "Explore",
        pages: [
          {
            title: "🧳 Wallet Explorer",
            href: "https://coti.nebula-tech.io/wallet-explorer",
            external: true,
          },
          {
            title: "🗺️ Worldmap",
            href: location.hostname.split(".")[0] === "testnet" ? "https://cotiworldmap.com/d/QQrcY-0Vk/node-explorer?var-network=TestNet" : "https://cotiworldmap.com",
            external: true,
          },
          {
            title: "🟩 Live Nodes List",
            href: location.hostname.split(".")[0] === "testnet" ? "https://coti-stats.innovunode.io/testnet" : "https://coti-stats.innovunode.io/",
            external: true,
          },
          {
            title: "💎 Treasury Stats",
            href: "https://coti.nebula-tech.io/treasury/statistics",
            external: true,
          },
          {
            title: "Coti on CoinMarketCap",
            href: "https://coinmarketcap.com/currencies/coti/",
            external: true,
          },
        ],
      },
      portfolio: {
        groupTitle: "Portfolio",
        pages: [
          {
            title: "Basic",
            href: "/portfolio-page",
          },
          {
            title: "Masonry",
            href: "/portfolio-masonry",
          },
          {
            title: "Grid View",
            href: "/portfolio-grid",
          },
          {
            title: "Parallax Effect",
            href: "/agency",
          },
        ],
      },
    },
  },
  account: {
    title: "Account",
    id: "account",
    children: {
      settings: {
        groupTitle: "Settings",
        pages: [
          {
            title: "General",
            href: "/account/?pid=general",
          },
          {
            title: "Security",
            href: "/account/?pid=security",
          },
          {
            title: "Notifications",
            href: "/account/?pid=notifications",
          },
          {
            title: "Billing",
            href: "/account/?pid=billing",
          },
        ],
      },
      signup: {
        groupTitle: "Sign up",
        pages: [
          {
            title: "Simple",
            href: "/signup",
          },
          {
            title: "Cover",
            href: "/signup-cover",
          },
        ],
      },
      signin: {
        groupTitle: "Sign in",
        pages: [
          {
            title: "Simple",
            href: "/signin-simple",
          },
          {
            title: "Cover",
            href: "/signin-cover",
          },
        ],
      },
      password: {
        groupTitle: "Password reset",
        pages: [
          {
            title: "Simple",
            href: "/password-reset-simple",
          },
          {
            title: "Cover",
            href: "/password-reset-cover",
          },
        ],
      },
      error: {
        groupTitle: "Error",
        pages: [
          {
            title: "Simple",
            href: "/not-found",
          },
          {
            title: "Cover",
            href: "/not-found-cover",
          },
        ],
      },
    },
  },
}
