import axiosInstance from "@/axiosInstance"
import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  artists: [],
  exhibitions: [],
  awards: [],
  links: [],
  followingList: [],
  followersList: [],
  errors: [],
}

const slice = createSlice({
  name: "artists",
  initialState,
  reducers: {
    listArtists(state, action) {
      state.artists = action.payload
    },
    listExhibitions(state, action) {
      state.exhibitions = action.payload
    },
    listAwards(state, action) {
      state.awards = action.payload
    },
    showArtist(state, action) {
      state.artist = action.payload
    },
    showArtistError(state, action) {
      state.errors.push(action.payload)
      console.log(action.payload)
      // window.location.replace("/not-found") // Redirect if the resource is not found
    },
    createArtist(state, action) {
      state.artist.push(action.payload)
      state.errors = []
    },
    createArtistError(state, action) {
      state.errors = action.payload
    },
    createExhibition(state, action) {
      state.exhibitions.push(action.payload)
      state.errors = []
    },
    createExhibitionError(state, action) {
      state.errors = action.payload
    },
    createAward(state, action) {
      state.awards.push(action.payload)
      state.errors = []
    },
    createAwardError(state, action) {
      state.errors = action.payload
    },
    editArtist(state, action) {
      state.artist = action.payload
    },
    updateArtist(state, action) {
      console.log(action)
      console.log(action.payload)
      state.artist.profile_picture_current.original = action.payload
      state.errors = []
    },
    updateArtistError(state, action) {
      state.errors = action.payload
    },
    listArtistLinks(state, action) {
      state.links = action.payload
    },
    listArtistLinksError(state, action) {
      state.errors = action.payload
    },
    updateArtistLinks(state, action) {
      state.links = action.payload
      state.errors = []
    },
    updateArtistLinksError(state, action) {
      state.errors = action.payload
    },
    updateExhibition(state, action) {
      console.log(action)
      console.log(action.payload)
      state.exhibitions = state.exhibitions.map((exhibition) => (exhibition.id === action.payload.id ? action.payload : exhibition))
      state.errors = []
    },
    updateExhibitionError(state, action) {
      state.errors = action.payload
    },
    updateAward(state, action) {
      console.log(action)
      console.log(action.payload)
      state.awards = state.awards.map((item) => (item.id === action.payload.id ? action.payload : item))
      state.errors = []
    },
    updateAwardError(state, action) {
      state.errors = action.payload
    },
    deleteArtist(state, action) {
      state.artist = state.artists.filter((artist, index) => artist.id !== action.payload.id)
      state.errors = []
    },
    deleteExhibition(state, action) {
      state.exhibitions = state.exhibitions.filter((exhibition, index) => exhibition.id !== action.payload.id)
      state.errors = []
    },
    deleteExhibitionError(state, action) {
      state.errors = action.payload
    },
    deleteAward(state, action) {
      state.awards = state.awards.filter((award, index) => award.id !== action.payload.id)
      state.errors = []
    },
    deleteAwardError(state, action) {
      state.errors = action.payload
    },
    followArtist(state, action) {
      state.artist.follower_count += 1
      state.errors = []
    },
    unfollowArtist(state, action) {
      state.artist.follower_count -= 1
      state.errors = []
    },
    getArtistFollowing(state, action) {
      state.followingList = action.payload
      state.errors = []
    },
    getArtistFollowers(state, action) {
      state.followersList = action.payload
      state.errors = []
    },
  },
})

export const { reducer } = slice

export const listArtists = () => async (dispatch) => {
  axiosInstance.get(`/artists/?count=100`).then((res) => {
    dispatch(slice.actions.listArtists(res.data))
  })
}
export const listExhibitions = (artist, setLoading) => async (dispatch) => {
  setLoading && setLoading(true)
  axiosInstance.get(`/artists/${artist.id}/exhibitions/`).then((res) => {
    dispatch(slice.actions.listExhibitions(res.data))
    setLoading && setLoading(false)
  })
}
export const listAwards = (artist, setLoading) => async (dispatch) => {
  setLoading && setLoading(true)
  axiosInstance.get(`/artists/${artist.id}/awards/`).then((res) => {
    dispatch(slice.actions.listAwards(res.data))
    setLoading && setLoading(false)
  })
}
export const showArtist = (handle, setLoading, setOpenDeleteComment, setEdit) => async (dispatch) => {
  // console.log("showArtist", handle)
  console.log("handle:", handle)
  axiosInstance
    .get(`/artists/${handle}`)
    .then((res) => {
      // console.log("artists: found")
      // console.log(res)
      // console.log(res.data)
      setEdit && setEdit(false)
      dispatch(slice.actions.showArtist(res.data))
      setOpenDeleteComment && setOpenDeleteComment(false)
      setLoading && setLoading(false)
    })
    .catch((err) => {
      console.log("artists: not found")
      console.log(err)
      console.log(err.response)
      console.log(err.response.data)
      if (err.response.data.detail.startsWith("Try alternative artist handle:")) {
        if (err.response.data.handle) {
          window.location.href = `/artists/${err.response.data.handle}`
        }
      }
      dispatch(slice.actions.showArtistError(err.response.data))
    })
}

export const createExhibition = (newRow, rows, setRows, countries) => async (dispatch) => {
  console.log("createExhibition")
  console.log("newRow", newRow)
  return axiosInstance
    .post(`/artists/exhibitions/`, newRow)
    .then((res) => {
      console.log("exhibition created")
      dispatch(slice.actions.createExhibition(res.data))
      const updatedRow = { ...newRow, country: { code: newRow.country, name: countries.find(({ value: optionValue }) => newRow.country === optionValue.label) }, isNew: false }
      console.log("updatedRow:", updatedRow)
      console.log("rows:", rows)
      setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)))
      return res.data
    })
    .catch((error) => {
      console.log("error")
      console.log(error.response)
      console.log(error.response.data)
      dispatch(slice.actions.createExhibitionError(error.response.data))
    })
}
export const createAward = (newRow, rows, setRows, countries) => async (dispatch) => {
  console.log("createAward")
  console.log("newRow", newRow)
  return axiosInstance
    .post(`/artists/awards/`, newRow)
    .then((res) => {
      console.log("award created")
      dispatch(slice.actions.createAward(res.data))
      const updatedRow = { ...newRow, country: { code: newRow.country, name: countries.find(({ value: optionValue }) => newRow.country === optionValue.label) }, isNew: false }
      console.log("updatedRow:", updatedRow)
      console.log("rows:", rows)
      setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)))
      return res.data
    })
    .catch((error) => {
      console.log("error")
      console.log(error.response)
      console.log(error.response.data)
      dispatch(slice.actions.createAwardError(error.response.data))
    })
}
export const updateArtist = (formState, artist, setEditing, setLoading, setChangeCover, setCoverChanged, setOpenPopup, closePopup, setProfilePicture) => async (dispatch) => {
  // console.log("updateArtist")
  // console.log(formState)
  axiosInstance
    .patch(`/artists/${artist.id}/`, formState.values)
    .then((res) => {
      // console.log("updateArtist Successful")
      // console.log("success2")
      if (closePopup) {
        // console.log("closePopup")
        closePopup()
      }
      if (setProfilePicture) {
        setProfilePicture(formState.values.profile_picture_current?.image.original)
      }
      if (setChangeCover) {
        setChangeCover(false)
      }
      if (setCoverChanged) {
        setCoverChanged(false)
      }
      if (setOpenPopup) {
        setOpenPopup(false)
      }
      // console.log("success3")
      dispatch(slice.actions.updateArtist(res.data))
      // console.log(artist.handle)
      // console.log(res.data.handle)
      // console.log("res.data.handle")
      if (res.data.handle === artist.handle) {
        // Handle was not updated
        // console.log("Showing artist new")
        dispatch(showArtist(res.data.handle, setLoading, null, setEditing))
      } else {
        // Handle was updated
        // console.log("Handle was updated")
        window.location.href = `/artists/${res.data.handle}`
        // dispatch(slice.actions.showArtist(res.data))
      }
      // console.log("success4")
      // console.log(artist)
    })
    .catch((error) => {
      console.log("error")
      console.log(error.response)
      console.log(error.response.data)
      dispatch(slice.actions.updateArtistError(error.response.data))
      setLoading && setLoading(false)
    })
}
export const listArtistLinks = (artist, setLoading) => async (dispatch) => {
  console.log("listArtistLinks")
  axiosInstance
    .get(`/artists/links/${artist.id}/`)
    .then((res) => {
      setLoading && setLoading(false)
      dispatch(slice.actions.listArtistLinks(res.data))
    })
    .catch((error) => {
      console.log("error")
      console.log(error.response)
      console.log(error.response.data)
      dispatch(slice.actions.listArtistLinksError(error.response.data))
      setLoading && setLoading(false)
    })
}
export const updateArtistLinks = (links, artist, setLoading, setOpenPopup, setDeletions) => async (dispatch) => {
  console.log("updateArtistLinks")
  console.log("links:", links)
  axiosInstance
    .put(`/artists/links/${artist.id}/`, links)
    .then((res) => {
      setLoading && setLoading(false)
      setOpenPopup && setOpenPopup(false)
      setDeletions && setDeletions([])
      dispatch(slice.actions.updateArtistLinks(res.data))
    })
    .catch((error) => {
      console.log("error")
      console.log(error.response)
      console.log(error.response.data)
      dispatch(slice.actions.updateArtistLinkError(error.response.data))
      setLoading && setLoading(false)
    })
}

export const updateExhibition = (newRow, rows, setRows, countries) => async (dispatch) => {
  console.log("updateExhibition")
  console.log("newRow", newRow)
  return axiosInstance
    .put(`/artists/exhibitions/${newRow.id}/`, newRow)
    .then((res) => {
      console.log("exhibition created")
      dispatch(slice.actions.updateExhibition(res.data))
      const updatedRow = { ...newRow, country: { code: newRow.country, name: countries.find(({ value: optionValue }) => newRow.country === optionValue.label) }, isNew: false }
      console.log("updatedRow:", updatedRow)
      console.log("rows:", rows)
      setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)))
      return res.data
    })
    .catch((error) => {
      console.log("error")
      console.log(error.response)
      console.log(error.response.data)
      dispatch(slice.actions.updateExhibitionError(error.response.data))
    })
}
export const updateAward = (newRow, rows, setRows, countries) => async (dispatch) => {
  console.log("updateAward")
  console.log("newRow", newRow)
  return axiosInstance
    .put(`/artists/awards/${newRow.id}/`, newRow)
    .then((res) => {
      console.log("award updated")
      dispatch(slice.actions.updateAward(res.data))
      const updatedRow = { ...newRow, country: { code: newRow.country, name: countries.find(({ value: optionValue }) => newRow.country === optionValue.label) }, isNew: false }
      console.log("updatedRow:", updatedRow)
      console.log("rows:", rows)
      setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)))
      return res.data
    })
    .catch((error) => {
      console.log("error")
      console.log(error.response)
      console.log(error.response.data)
      dispatch(slice.actions.updateAwardError(error.response.data))
    })
}

export const deleteArtist = (artist) => async (dispatch) => {
  axiosInstance
    .delete(`/artists/${artist.id}/`, artist)
    .then((res) => {
      dispatch(slice.actions.deleteArtist(artist))
    })
    .catch((err) => {
      dispatch(slice.actions.deleteArtistError(err.response.data))
    })
}
export const deleteExhibition = (id) => async (dispatch) => {
  return axiosInstance
    .delete(`/artists/exhibitions/${id}/`)
    .then((res) => {
      dispatch(slice.actions.deleteExhibition(res))
      return res
    })
    .catch((err) => {
      dispatch(slice.actions.deleteExhibitionError(err.response.data))
      return err
    })
}
export const deleteAward = (id) => async (dispatch) => {
  return axiosInstance
    .delete(`/artists/awards/${id}/`)
    .then((res) => {
      dispatch(slice.actions.deleteAward(res))
      return res
    })
    .catch((err) => {
      dispatch(slice.actions.deleteAwardError(err.response.data))
      return err
    })
}

export const followArtist = (formState, setFollowId) => async (dispatch) => {
  axiosInstance
    .post(`/artists/following/`, formState)
    .then((res) => {
      // console.log(res)
      setFollowId(res.data.id)
      // The followe count is returned but currently it's managed in react
      // HTMLFormControlsCollection.log
      // console.log("follow dispatched")
      dispatch(showArtist(formState.handle))
      // dispatch(slice.actions.followArtist({}))
    })
    .catch((err) => {
      console.log(err)
      // dispatch(slice.actions.updateArtistError(err.response.data))
    })
}
export const unfollowArtist = (formState) => async (dispatch) => {
  axiosInstance
    .delete(`/artists/following/${formState.id}`, formState)
    .then((res) => {
      // The followe count is returned but currently it's managed in react
      // console.log(res)
      // console.log(res.data)
      // console.log(formState)
      // console.log("unfollow dispatched")
      dispatch(showArtist(formState.handle))
    })
    .catch((err) => {
      console.log(err)
      // dispatch(slice.actions.updateArtistError(err.response.data))
    })
}
export const getArtistFollowing = (artistId, setLoading) => async (dispatch) => {
  axiosInstance
    .get(`/artists/${artistId}/following/`)
    .then((res) => {
      // console.log(res)
      dispatch(slice.actions.getArtistFollowing(res.data))
      setLoading(false)
    })
    .catch((err) => {
      console.log(err)
      // dispatch(slice.actions.updateArtistError(err.response.data))
    })
}
export const getArtistFollowers = (artistId, setLoading) => async (dispatch) => {
  axiosInstance
    .get(`/artists/${artistId}/followers/`)
    .then((res) => {
      // console.log(res)
      dispatch(slice.actions.getArtistFollowers(res.data))
      setLoading(false)
    })
    .catch((err) => {
      console.log(err)
      // dispatch(slice.actions.updateArtistError(err.response.data))
    })
}

export default slice
