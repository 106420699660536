import { combineReducers } from "@reduxjs/toolkit"
import { reducer as artistReducer } from "../slices/artists"
import { reducer as commentReducer } from "../slices/comments"
import { reducer as imageReducer } from "../slices/images"
import { reducer as nftReducer } from "../slices/nfts"
import { reducer as notificationReducer } from "../slices/notifications"
import { reducer as orderReducer } from "../slices/orders"
import { reducer as pricingReducer } from "../slices/pricing"
import { reducer as tokenReducer } from "../slices/tokens"
import { reducer as transactionReducer } from "../slices/transactions"
import { reducer as userReducer } from "../slices/users"

const rootReducer = combineReducers({
  user: userReducer,
  notification: notificationReducer,
  artists: artistReducer,
  images: imageReducer,
  tokens: tokenReducer,
  comments: commentReducer,
  nfts: nftReducer,
  orders: orderReducer,
  pricing: pricingReducer,
  transactions: transactionReducer,
})

export default rootReducer
