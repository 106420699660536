import usePageTracking from "@/utils/usePageTracking.js"
import { useRoutes } from "react-router-dom"

import routes from "./Routes"

import { useSelector } from "@/reducerStore"
import React from "react"

function App() {
  usePageTracking()
  var { user, isAuthenticated } = useSelector((state) => state.user)

  React.useEffect(() => {
    if (user?.settings) {
      if (user?.settings?.network === "TestNet" && location.hostname.split(".")[0] !== "testnet") {
        window.location.href = `${location.protocol}//${user?.settings?.network === "TestNet" && "testnet."}${location.hostname.split(".").reverse().splice(0, 2).reverse().join(".")}:${location.port}`
      }
      if (user?.settings?.network === "MainNet" && location.hostname.split(".")[0] === "testnet") {
        window.location.href = `${location.protocol}//${location.hostname.split(".").splice(1, 20).join(".")}:${location.port}`
      }
    }
  }, [user])

  return useRoutes(routes)
}

export default App
