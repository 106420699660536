import WithLayout from "@/views/layouts/WithLayout"
import React, { lazy, Suspense } from "react"
import { Main } from "./views/layouts/layouts"

//<Suspense fallback={<LoadingScreen />}>
const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<div />}>
      <Component {...props} />
    </Suspense>
  )

// Products
const ListTokensView = Loadable(lazy(() => import("@/views/Tokens/List/Home")))
const ShowTokenView = Loadable(lazy(() => import("@/views/Tokens/Show/Show")))

// Auth
const PasswordResetCoverView = Loadable(lazy(() => import("@/views/Auth/PasswordResetCover")))
const PasswordResetView = Loadable(lazy(() => import("@/views/Auth/PasswordReset")))
const SigninSimpleView = Loadable(lazy(() => import("@/views/Auth/Signin")))
const SignupSimpleView = Loadable(lazy(() => import("@/views/Auth/Signup")))
const VerifyEmailView = Loadable(lazy(() => import("@/views/Auth/VerifyEmail")))
const SettingsView = Loadable(lazy(() => import("@/views/Auth/Settings")))

// Layout Options
var secondaryBackgroundColor = "rgba(245,245,246,255)"
var yPadding = "32px 0"

const routes = [
  {
    path: "/",
    element: <WithLayout component={ListTokensView} layout={Main} padding={yPadding} backgroundColor={"#FCFCFC"} />,
  },
  {
    path: "/currencies/:symbol",
    element: <WithLayout component={ShowTokenView} layout={Main} padding={yPadding} backgroundColor={"#FCFCFC"} />,
  },
]

export default routes
