import axios from "axios"
import { useNavigate } from "react-router-dom"

// Get baseURL from the address bar
var baseURL = window.location.protocol + (import.meta.env.PROD ? "//api." : "//") + window.location.hostname + (!import.meta.env.PROD ? ":8000" : "")

const minutes = 60 * 1000
const axiosInstance = axios.create({
  baseURL: baseURL,
  timeout: 5 * minutes, // Has to be large to give it time to upload large images
  headers: {
    Authorization: localStorage.getItem("access_token") ? "JWT " + localStorage.getItem("access_token") : null,
    "Content-Type": "application/json",
    accept: "application/json",
  },
})

// Handle connection problems
axiosInstance.interceptors.response.use(
  (response) => {
    return response
  },
  async (error) => {
    const originalConfig = error.config

    if (error.message === "Network Error" && typeof error.response === "undefined") {
      console.log("Server connection errror!", error.message)
      return Promise.reject(error)
    }

    // Handle Authorization Problems (401)
    if (error.response.status === 401) {
      // Handle invalid refresh token
      // console.log("originalConfig", originalConfig)
      // console.log("originalConfig.url", originalConfig.url)
      // console.log('originalConfig.url === "/users/user/"', originalConfig.url === "/users/user/")
      // Sleep function for debugging
      // function sleep(ms) {
      //   return new Promise((resolve) => setTimeout(resolve, ms))
      // }
      // await sleep(30 * 1000)
      if (originalConfig.url === "/users/user/") {
        localStorage.clear()
        const navigate = useNavigate()
        navigate("/signin/")
        // window.location.href = "/signin/"
      }
      // Handle invalid access token
      // Note: This should not be handling invalid refresh token
      if (originalConfig.url !== "users/jwt/refresh/" && error.response.data.code === "token_not_valid" && error.response.statusText === "Unauthorized") {
        console.log("Handle invalid access token")
        // In this case, refresh the access token
        originalConfig._retry = true
        // originalRequest._retry = true
        const refreshToken = localStorage.getItem("refresh_token")

        if (refreshToken) {
          const tokenParts = JSON.parse(atob(refreshToken.split(".")[1]))

          // exp date in token is expressed in seconds, while now() returns milliseconds:
          const now = Math.ceil(Date.now() / 1000)

          if (tokenParts.exp > now) {
            try {
              console.log("call refreshAccessToken")
              return refreshAccessToken(refreshToken, originalConfig)
            } catch (error) {
              // Catch errors in the case that the refresh token didn't work
              console.log("Token refresh unsuccessful")
              window.location.href = "/signin/"
            }
          } else {
            console.log("Refresh token is expired")
            window.location.href = "/signin/"
          }
        } else {
          console.log("Refresh token does not exist.")
          window.location.href = "/signin/"
        }
      }
    }

    // specific error handling done elsewhere
    return Promise.reject(error)
  }
)

const refreshAccessToken = (refreshToken, originalConfig) => {
  console.log("refreshAccessToken axios call")
  return axiosInstance.post("users/jwt/refresh/", { refresh: refreshToken }).then((response) => {
    console.log("success/then:", response.data)
    localStorage.setItem("access_token", response.data.access)
    localStorage.setItem("refresh_token", response.data.refresh)

    axiosInstance.defaults.headers["Authorization"] = "JWT " + response.data.access
    originalConfig.headers["Authorization"] = "JWT " + response.data.access
    return axiosInstance(originalConfig)
  })
  // .catch((err) => {
  //   // I think the logic flow never gets taken here, because it gets caught by the interceptor, causing the loop
  //   console.log("refreshAccessToken error:", err)
  //   return Promise.reject("Token refresh unsuccessful")
  // })
}

export default axiosInstance
