import { AppBar, Toolbar } from "@mui/material"
import { makeStyles } from "@mui/styles"
import clsx from "clsx"
import PropTypes from "prop-types"
import React from "react"
import Logo from "../../../../../../components/dashboard/Logo"
import { SignUpButton } from "../../../Main/components/Topbar/Links"

const useStyles = makeStyles((theme) => ({
  toolbar: {
    maxWidth: theme.layout.contentWidth,
    width: "100%",
    margin: "0 auto",
    padding: theme.spacing(0, 2),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(0, 8),
    },
  },
  logoContainer: {
    top: 0,
    width: 100,
    height: 28,
    [theme.breakpoints.up("md")]: {
      width: 120,
      height: 32,
    },
  },
  logoImage: {
    width: "100%",
    height: "100%",
  },
}))

const Topbar = ({ themeMode, className, ...rest }) => {
  const classes = useStyles()

  return (
    <AppBar position="sticky" color="default">
      <Toolbar className={clsx(classes.toolbar, className)} sx={{ justifyContent: "space-between" }} {...rest}>
        {/* <div className={classes.logoContainer}>
        <a href="/" title="Coti Market Cap">
          <Image
            className={classes.logoImage}
            src={themeMode === 'light' ? 'https://assets.maccarianagency.com/the-front/logos/logo.svg' : 'https://assets.maccarianagency.com/the-front/logos/logo-negative.svg'}
            alt="thefront"
            lazy={false}
          />
        </a>
      </div> */}
        <Logo size="large" />
        {window.location.pathname === "/become-a-tpg-artist" && (
          <div>
            <SignUpButton text="Create New Account" />
          </div>
        )}
      </Toolbar>
    </AppBar>
  )
}

Topbar.propTypes = {
  className: PropTypes.string,
  themeMode: PropTypes.string.isRequired,
}

export default Topbar
