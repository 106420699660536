/**
 * Caution: Consider this file when using react-scripts
 *
 * You may delete this file and its occurrences from the project filesystem if you are using GatsbyJS or NextJS version
 */
import React, { StrictMode } from "react"
import { HelmetProvider } from "react-helmet-async"
import { BrowserRouter } from "react-router-dom"
import { createRoot } from "react-dom/client"
import { createBrowserHistory } from "history"
import { Provider as ReduxProvider } from "react-redux"
import { StyledEngineProvider } from "@mui/material/styles" // See https://stackoverflow.com/a/69324045
import store from "./reducerStore"
import * as serviceWorker from "./views/layouts/serviceWorker"
import App from "./App"
import "react-toastify/dist/ReactToastify.css"

export const browserHistory = createBrowserHistory()

const root = createRoot(document.getElementById("root"))

root.render(
  <HelmetProvider>
    <ReduxProvider store={store}>
      <BrowserRouter history={browserHistory}>
        <StyledEngineProvider injectFirst>
          <App />
        </StyledEngineProvider>
      </BrowserRouter>
    </ReduxProvider>
  </HelmetProvider>
)

serviceWorker.unregister()
