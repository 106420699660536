import axiosInstance from "@/axiosInstance"
import { createSlice } from "@reduxjs/toolkit"
import { toast } from "react-toastify"

const initialState = {
  transactions: [],
  transaction: {}, // Should be an object rather than an array
  errors: [],
}

const slice = createSlice({
  name: "transactions",
  initialState,
  reducers: {
    initialiseTransactions(state) {
      state.transactions = initialState.transactions
      state.transaction = initialState.transaction
      state.errors = initialState.errors
    },
    listTransactions(state, action) {
      state.transactions = action.payload
    },
    showTransaction(state, action) {
      state.transaction = action.payload
    },
    showTransaction_error(state, action) {
      state.errors.push(action.payload)
      console.log(action.payload)
      // window.location.replace("/not-found") // Redirect if the resource is not found
    },
    createTransaction(state, action) {
      state.transaction = action.payload
      state.errors = []
    },
    createTransaction_error(state, action) {
      state.errors = action.payload
    },
    editTransaction(state, action) {
      state.transaction = action.payload
    },
    updateTransaction(state, action) {
      // listTransactions()
      state.errors = []
    },
    updateTransaction_error(state, action) {
      state.errors = action.payload
    },
  },
})

export const { reducer } = slice

export const initialiseTransactions = () => async (dispatch) => {
  dispatch(slice.actions.initialiseTransactions())
}
export const listTransactions = () => async (dispatch) => {
  axiosInstance.get(`/orders/transactions/`).then((res) => {
    console.log("listTransactions")
    console.log(res.data)
    dispatch(slice.actions.listTransactions(res.data))
  })
}

export const showTransaction = (pk) => async (dispatch) => {
  axiosInstance
    .get(`/orders/transactions/${pk}`)
    .then((res) => {
      dispatch(slice.actions.showTransaction(res.data))
    })
    .catch((err) => {
      dispatch(slice.actions.showTransaction_error(err.response?.data))
    })
}

export const createTransaction = (formState) => async (dispatch) => {
  axiosInstance
    .post(`/transactions/`, formState.values)
    .then((res) => {
      dispatch(slice.actions.createTransaction(res.data))
    })
    .catch((err) => {
      console.log(err)
      console.log(err.response.data)
      if (err.response.data) {
        // toast.error(err.response.data.split('\n')[0], {
        toast.error("Something went wrong. If the problem persists, please let us know.", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      }
      dispatch(slice.actions.createTransaction_error(err.response.data))
    })
}

// export const editTransaction = (transactions) => async (dispatch) => {
//   dispatch(slice.actions.editTransaction(transactions))
// }

// export const updateTransaction = (formState, setOpenEditBrief, setOpenEditArtworkInformation, setOpenEditPrice, setLoading) => async (dispatch) => {
//   console.log('formState')
//   console.log(formState)
//   axiosInstance
//     .put(`/orders/transactions/${formState.values.id}/`, formState.values)
//     .then((res) => {
//       setOpenEditBrief && setOpenEditBrief(false)
//       setOpenEditArtworkInformation && setOpenEditArtworkInformation(false)
//       setOpenEditPrice && setOpenEditPrice(false)
//       setLoading && setLoading(false)
//       dispatch(slice.actions.updateTransaction(res.data))
//       dispatch(showTransaction(formState.values.id))
//     })
//     .catch((err) => {
//       console.log(err)
//       console.log(err.response.data)
//       dispatch(slice.actions.updateTransaction_error(err.response.data))
//       if(err.response.data.detail){
//         toast.info(err.response.data.detail, {
//           position: "top-center",
//           autoClose: 5000,
//           hideProgressBar: false,
//           closeOnClick: true,
//           pauseOnHover: true,
//           draggable: true,
//           progress: undefined,
//         })
//       }
//       setLoading && setLoading(false)
//     })
// }

export default slice
