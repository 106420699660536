import Brightness4Icon from "@mui/icons-material/Brightness4"
import LightModeIcon from "@mui/icons-material/LightMode"
import Box from "@mui/material/Box"
import IconButton from "@mui/material/IconButton"
import { useTheme } from "@mui/material/styles"
import * as React from "react"
export default function MuiSwitch({ onChange, checked }) {
  const theme = useTheme()
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
        // bgcolor: "background.default",
        color: "text.primary",
        borderRadius: 1,
        // p: 3,
      }}
    >
      <IconButton sx={{ ml: 1 }} onClick={onChange} color="inherit">
        {theme.palette.mode === "dark" ? <LightModeIcon color={"secondary"} /> : <Brightness4Icon style={{ color: "#888" }} />}
      </IconButton>
    </Box>
  )
}

//rgb(255, 225, 0)
