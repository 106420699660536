import axiosInstance from "@/axiosInstance"
import { createSlice } from "@reduxjs/toolkit"
import { toast } from "react-toastify"

const initialState = {
  tokensList: [],
  tokens: [],
  token: {}, // Should be an object rather than an array
  errors: [],
}

const slice = createSlice({
  name: "tokens",
  initialState,
  reducers: {
    initialize(state, action) {
      const { tokens } = action.payload
      state.tokens = tokens
      state.token = initialState.token
      state.errors = initialState.errors
    },
    listTokens(state, action) {
      state.tokens = action.payload
      state.tokensList = [...state.tokensList, ...action.payload.results]
    },
    showToken(state, action) {
      state.token = action.payload
    },
    showToken_error(state, action) {
      state.errors.push(action.payload)
      console.log(action.payload)
      // window.location.replace("/not-found") // Redirect if the resource is not found
    },
    createToken(state, action) {
      state.token = action.payload
      state.errors = []
    },
    createToken_error(state, action) {
      state.errors = action.payload
    },
    editToken(state, action) {
      state.token = action.payload
    },
    updateToken(state, action) {
      // listTokens()
      state.errors = []
    },
    updateToken_error(state, action) {
      state.errors = action.payload
    },
    deleteToken(state, action) {
      state.token = state.tokens.filter((Token, index) => Token.id !== action.payload.id)
      state.errors = []
    },
    getLikedBy(state, action) {
      state.likedByList = action.payload
      state.errors = []
    },
  },
})

export const { reducer } = slice

export const initializeTokens = (page, setLoading, user) => async (dispatch) => {
  axiosInstance.get(`https://admin.cotiworldmap.com/cotimarketcap/tokens/?network=${user?.settings?.network}`).then((res) => {
    // console.log("initializeTokens", res.data)
    setLoading && setLoading(false)

    // console.log("newArray", newArray)
    // console.log(newArray.length)
    dispatch(slice.actions.initialize({ tokens: res.data.tokens }))
  })
}

export const searchTokens = (query, page, setLoading, similarity) => async (dispatch) => {
  axiosInstance.get(`/tokens/search?query=${query}&page=${page}${similarity ? `&similarity=${similarity}` : ""}`).then((res) => {
    dispatch(slice.actions.listTokens(res.data))
    // console.log("Search results:", res.data)
    setLoading && setLoading(false)
  })
}
export const showToken = (pk) => async (dispatch) => {
  axiosInstance
    .get(`/tokens/${pk}`)
    .then((res) => {
      dispatch(slice.actions.showToken(res.data))
    })
    .catch((err) => {
      dispatch(slice.actions.showToken_error(err.response?.data))
    })
}

export const createToken = (formData, setOpenPopup, setLoading) => async (dispatch) => {
  axiosInstance
    .post(`/tokens/`, formData)
    .then((res) => {
      dispatch(slice.actions.createToken(res.data))
      setOpenPopup(true)
      setLoading(false)
    })
    .catch((err) => {
      console.log(err.response.data)
      if (err.response.data.file) {
        toast.info(err.response.data.file[0], {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      }
      dispatch(slice.actions.createToken_error(err.response.data))
      setLoading(false)
    })
}

export const editToken = (tokens) => async (dispatch) => {
  dispatch(slice.actions.editToken(tokens))
}

export const updateToken = (formData, setOpenEditBrief, setOpenEditArtworkInformation, setOpenEditPrice, setLoading) => async (dispatch) => {
  console.log("formData")
  console.log(formData)
  axiosInstance
    .put(`/tokens/${formData.id}/`, formData)
    .then((res) => {
      setOpenEditBrief && setOpenEditBrief(false)
      setOpenEditArtworkInformation && setOpenEditArtworkInformation(false)
      setOpenEditPrice && setOpenEditPrice(false)
      setLoading && setLoading(false)
      dispatch(slice.actions.updateToken(res.data))
      dispatch(showToken(formData.id))
    })
    .catch((err) => {
      console.log(err)
      console.log(err.response.data)
      dispatch(slice.actions.updateToken_error(err.response.data))
      if (err.response.data.detail) {
        toast.info(err.response.data.detail, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      }
      setLoading && setLoading(false)
    })
}
export const likeToken = (formState) => async (dispatch) => {
  axiosInstance
    .put(`/tokens/${formState.id}/like/`, formState)
    .then((res) => {
      console.log(res.data)
      // if(formState.like){
      //   setIsLiked(true)
      //   setLikes((prevLikes) => prevLikes + 1)
      // } else {
      //   setIsLiked(false)
      //   setLikes((prevLikes) => prevLikes - 1)
      // }
    })
    .catch((err) => {
      console.log(rr.response.data)
      dispatch(slice.actions.updateToken_error(err.response.data))
    })
}

export const deleteToken = (token) => async (dispatch) => {
  axiosInstance
    .delete(`/tokens/${token.id}/`, token)
    .then((res) => {
      dispatch(slice.actions.deleteToken(token))
    })
    .catch((err) => {
      dispatch(slice.actions.deleteTokenFail(err.response.data))
    })
}

export const getLikedBy = (token, setLoading) => async (dispatch) => {
  axiosInstance
    .get(`/tokens/${token.id}/likes/`)
    .then((res) => {
      console.log(res.data)
      dispatch(slice.actions.getLikedBy(res.data))
      setLoading(false)
    })
    .catch((err) => {
      console.log(err)
      // dispatch(slice.actions.updateArtist_error(err.response.data))
    })
}

export default slice
