import { responsiveFontSizes } from "@mui/material"
import { createTheme } from "@mui/material/styles"
import { dark, light } from "./palette"

const getTheme = (mode) => {
  console.log("mode", mode)
  return responsiveFontSizes(
    createTheme({
      palette: mode === "light" ? light : dark,
      layout: {
        contentWidth: 1236,
      },
      typography: {
        fontFamily: "Lato",
      },
      zIndex: {
        appBar: 1200,
        drawer: 1100,
      },
    })
  )
}

export default getTheme
