import axiosInstance from "@/axiosInstance"
import artistSlice, { showArtist } from "@/slices/artists"
import { initializeUser } from "@/slices/users"
import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  images: [],
  errors: [],
}

const slice = createSlice({
  name: "images",
  initialState,
  reducers: {
    listImages(state, action) {
      state.images = action.payload
    },
    showImage(state, action) {
      console.log("action.payload", action.payload)
      state.image = action.payload
    },
    showImage_error(state, action) {
      state.errors.push(action.payload)
      console.log(action.payload)
      // window.location.replace("/not-found") // Redirect if the resource is not found
    },
    createImage(state, action) {
      state.images.push(action.payload)
      state.errors = []
    },
    createImage_error(state, action) {
      state.errors = action.payload
    },
    editImage(state, action) {
      state.image = action.payload
    },
    updateImage(state, action) {
      // listImages()
      state.errors = []
    },
    updateImage_error(state, action) {
      state.errors = action.payload
    },
    deleteImage(state, action) {
      state.image = state.images.filter((image, index) => image.id !== action.payload.id)
      state.errors = []
    },
  },
})

export const { reducer } = slice

// export const listImages = () => async (dispatch) => {
//   axiosInstance.get(`/images/`).then((res) => {
//     dispatch(slice.actions.listImages(res.data))
//   })
// }
export const showImage = (handle, setLoading, setOpenDeleteComment, setEdit) => async (dispatch) => {
  // console.log("showImage", handle)
  axiosInstance
    .get(`/images/${handle}`)
    .then((res) => {
      setEdit && setEdit(false)
      setOpenDeleteComment && setOpenDeleteComment(false)
      setLoading && setLoading(false)
      dispatch(slice.actions.showImage(res.data))
    })
    .catch((err) => {
      dispatch(slice.actions.showImage_error(err.response.data))
    })
}

export const createImage = (profile_picture, cover_picture, artist, setCoverPicture, setChangeCover, setCoverChanged, setChangeProfilePicture, setProfilePictureChanged, setLoading,  setOpenPopup, closePopup, setProfilePicture) => async (dispatch) => {
  var fd = new FormData()
  console.log(profile_picture)
  console.log(cover_picture)
  if(profile_picture) {
    fd.append("profile_picture", true)
    fd.append("original", profile_picture)
  }
  if(cover_picture){
    fd.append("cover_picture", true)
    fd.append("original", cover_picture)
  }
  // Display the key/value pairs
  for (var pair of fd.entries()) {
      console.log(pair[0]+ ', ' + pair[1]); 
  }

  axiosInstance
    .post(`/images/`, fd)
    .then((res) => {
      // console.log("updateImage Successful")
      // console.log("success2")
      console.log(res.data)
      console.log("Debug 1")
      if (setProfilePicture ) {
        console.log("setProfilePicture")
        console.log(res.data.image.original)
        setProfilePicture(`${res.data.image.original}?${Date.now()}`)
      }
      console.log("Debug 2")
      if (setCoverPicture) {
        console.log("setCoverPicture")
        setCoverPicture(res.data.image.original)
      }
      console.log("Debug 4")
      console.log(setLoading)
      if (setLoading ) {
        setLoading(false)
      }
      console.log("Debug 5")
      if (setChangeCover ) {
        setChangeCover(false)
      }
      console.log("Debug 5.1")
      if (setChangeProfilePicture ) {
        setChangeProfilePicture(false)
      }
      console.log("Debug 5.2")
      if (setProfilePictureChanged ) {
        setProfilePictureChanged(false)
      }
      console.log("Debug 6")
      if (setCoverChanged ) {
        setCoverChanged(false)
      }
      console.log("Debug 7")
      if (setOpenPopup ) {
        setOpenPopup(false)
      }
      console.log("Debug 8")
      // console.log("success3")
      dispatch(slice.actions.createImage(res.data))
      console.log("Debug 9")
      if (closePopup) {
        // console.log("closePopup")
        closePopup()
      }
      // console.log(image.handle)
      // console.log(res.data.handle)
      // console.log("res.data.handle")
      // console.log("success4")
      // console.log(image)
      dispatch(initializeUser()) // I do this to refresh the user so the user icon gets updated
      dispatch(showArtist(artist.handle))
      artistSlice.actions.updateArtist(res.data)
    })
    .catch((error) => {
      console.log("error")
      if(setLoading ) {setLoading(false)}
      console.log(error.response)
      console.log(error.response.data)
      dispatch(slice.actions.updateImage_error(error.response.data))
    })
}

export const deleteImage = (image) => async (dispatch) => {
  axiosInstance
    .delete(`/images/${image.id}/`, image)
    .then((res) => {
      dispatch(slice.actions.deleteImage(image))
    })
    .catch((err) => {
      dispatch(slice.actions.deleteImageFail(err.response.data))
    })
}

export default slice
