import axiosInstance from "@/axiosInstance"
import { createSlice } from "@reduxjs/toolkit"
import { toast } from "react-toastify"

const initialState = {
  orders: [],
  purchases: [],
  sales: [],
  errors: [],
}

const slice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    getOrders(state, action) {
      state.orders = action.payload
    },
    getPurchases(state, action) {
      state.purchases = action.payload
    },
    getSales(state, action) {
      state.sales = action.payload
    },
    showOrder(state, action) {
      state.orders = action.payload
    },
    createOrder(state, action) {
      state.orders.push(action.payload)
      state.errors = []
    },
    createOrder_error(state, action) {
      state.errors = action.payload
    },
    // editOrder(state, action) {
    //   state.orders = action.payload
    // },
    // updateOrder(state, action) {
    //   // getOrders()
    //   state.errors = []
    // },
    // updateOrder_error(state, action) {
    //   state.errors = action.payload
    // },
    // deleteOrder(state, action) {
    //   state.orders = state.orders.filter(
    //     (Order, index) => Order.id !== action.payload.id
    //   )
    //   state.errors = []
    // },
  },
})

export const { reducer } = slice

export const getOrders = () => async (dispatch) => {
  axiosInstance.get(`/orders/`).then((res) => {
    dispatch(slice.actions.getOrders(res.data))
  })
}
export const getPurchases = () => async (dispatch) => {
  console.log("getPurchases!")
  axiosInstance.get(`/orders/purchases/`).then((res) => {
    dispatch(slice.actions.getPurchases(res.data))
  })
}
export const getSales = () => async (dispatch) => {
  console.log("getSales!")
  axiosInstance.get(`/orders/sales/`).then((res) => {
    dispatch(slice.actions.getSales(res.data))
  })
}
export const showOrder = (pk) => async (dispatch) => {
  console.log("getOrders axios failed")
  axiosInstance.get(`/orders/${pk}`).then((res) => {
    dispatch(slice.actions.showOrder(res.data))
  })
}

export const createOrder = (formData) => async (dispatch) => {
  // formData, setOpenPopup
  axiosInstance
    .post(`/orders/`, formData)
    .then((res) => {
      if (formData.order.method === "supra") {
        console.log("supra response")
        console.log(res)
        console.log(res.data)
        window.cardano
          .signTx(res.data.unsigned_cbor_hex)
          .then((witness) => {
            dispatch(submitSignedTransaction(res.data, witness))
          })
          .catch((err) => {
            console.log(err)
          })
        // window.location.replace(res.data.url)
      }
      if (formData.order.method === "stripe") {
        console.log("stripe response")
        console.log(res)
        console.log(res.data)
        window.location.replace(res.data.url)
        // alert(res.data.url)
      }
    })
    .catch((err) => {
      console.log(err.response)
      console.log(err.response.data.detail)
      toast.error(err.response.data.detail, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    })
}

export const submitSignedTransaction = (transaction, witness) => async (dispatch) => {
  console.log("submitSignedTransaction")
  console.log(transaction.id)
  axiosInstance
    .put(`/orders/${transaction.id}/submit_signed_transaction/`, { transaction, witness })
    .then((res) => {
      console.log("Transaction submitted")
      // window.location.replace("/wallet")
      toast.success(
        "Congratulations! Your payment has been submitted. Once we the transaction has been received, your NFT will be minted immediately. The process usually takes a few minutes, and we'll send you an email confirmation once the process has finished. Thanks you!",
        {
          position: "top-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            // backgroundColor: "blue",
            height: "100%",
            width: "60vw",
            marginLeft: `${-(60 / 2)}vw`,
          },
        }
      )
    })
    .catch((err) => {
      console.log(err)
    })
}
// export const updateOrder = (Order, setEditIndex) => async dispatch => {
//   axiosInstance
//     .put(`/orders/${Order.id}/`, Order)
//     .then(res => {
//       setEditIndex(-1)
//       dispatch(slice.actions.updateOrder(res.data))
//     })
//     .catch(err => {
//       dispatch(slice.actions.updateOrder_error(err.response.data))
//     })
// }

// export const deleteOrder = Order => async dispatch => {
//   axiosInstance
//     .delete(`/orders/${Order.id}/`, Order)
//     .then(res => {
//       dispatch(slice.actions.deleteOrder(Order))
//     })
//     .catch(err => {
//       dispatch(slice.actions.deleteOrderFail(err.response.data))
//     })
// }

export default slice
