import axiosInstance from "@/axiosInstance"
import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  unread_count: 0,
  notifications: null,
  errors: [],
}

const slice = createSlice({
  name: "users",
  initialState,
  reducers: {
    updateUnreadCount(state, action) {
      state.unread_count = action.payload.unread_count
    },
    updateNotifications(state, action) {
      state.notifications = action.payload
    },
  },
})

export const { reducer } = slice

export const showUnreadCount = () => async (dispatch) => {
  axiosInstance
    .get(`/users/unread_count/`)
    .then((res) => {
      // console.log("showUnreadCount successful")
      // console.log(res.data)
      dispatch(slice.actions.updateUnreadCount(res.data))
      // window.location.replace(res.data.url)
    })
    .catch((error) => {
      console.log(error.response)
      console.log(error.response.data)
      // dispatch(slice.actions.updateUser_error(error.response))
    })
}
export const get_notifications = () => async (dispatch) => {
  axiosInstance
    .get(`/users/get_notifications/`)
    .then((res) => {
      // console.log("get_notifications successful")
      // console.log(res.data)
      dispatch(slice.actions.updateNotifications(res.data))
      // window.location.replace(res.data.url)
    })
    .catch((error) => {
      console.log(error.response)
      console.log(error.response.data)
      // dispatch(slice.actions.updateUser_error(error.response))
    })
}
export const mark_all_notifications_as_read = () => async (dispatch) => {
  axiosInstance
    .get(`/users/mark_all_as_read/`)
    .then((res) => {
      // console.log("Mark as read successful")
      // console.log(res.data)
      dispatch(slice.actions.updateNotifications(res.data))
      // window.location.replace(res.data.url)
    })
    .catch((error) => {
      console.log(error.response)
      console.log(error.response.data)
      // dispatch(slice.actions.updateUser_error(error.response))
    })
}

export default slice
