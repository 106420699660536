import axiosInstance from "@/axiosInstance"
import { useDispatch, useSelector } from "@/reducerStore"
import { useEffect, useState } from "react"

export default function usePageTracking() {
  // const location = useLocation()
  const dispatch = useDispatch()
  const [initialized, setInitialized] = useState(false)
  var { user, isAuthenticated } = useSelector((state) => state.user)

  useEffect(() => {
    // if (initialized && !window.location.href.includes("localhost")) {
    // console.log("initialized", initialized, ReactGA)
    // console.log("considered")
    if (true && !window.location.href.includes("localhost") && localStorage.getItem("preventHit") !== "true") {
      // console.log("triggered")
      axiosInstance.post(`https://admin.cotiworldmap.com/cotimarketcap/hit/`, { network: location.hostname.split(".")[0] === "testnet" ? "TestNet" : "MainNet" })
    }
  }, [])
}
