import { Image } from "@/components/thefront/atoms"
import { images } from "@/data/assets"
import { makeStyles } from "@mui/styles"

const useStyles = makeStyles((theme) => ({
  logoContainer: {
    width: 200,
    height: 40,
    [theme.breakpoints.up("md")]: {
      width: 120,
      height: 60,
    },
  },
  logoImage: {
    width: "100%",
    height: "100%",
  },
}))

const Logo = ({ size }) => {
  const classes = useStyles()

  return (
    <div className={classes.logoContainer}>
      <Image
        className={classes.logoImage}
        // style={{ height: size === "large" ? "36px" : inherit }}
        // src={themeMode === 'light' ? logolight : 'https://assets.maccarianagency.com/the-front/logos/logo-negative.svg'}
        src={images.cotimarketcap.bannerLight}
        alt={import.meta.env.VITE_NAME}
        lazy={false}
      />
    </div>
  )
}

export default Logo
